import {
  KICKOFF_STANDARD_PRODUCT_ID,
  NUTRITION_ONLY_PRODUCT_ID,
  WORKOUT_AND_NUTRITION_PRODUCT_ID,
  WORKOUT_ONLY_PRODUCT_ID,
} from 'components/payment/common/constants'
import DetailItem from 'components/payment/common/detail-item'
import { useMemo } from 'react'

export const BULLET_POINTS_BY_PRODUCT_ID = {
  [WORKOUT_AND_NUTRITION_PRODUCT_ID]: [
    'Custom exercise program that meets you where you’re at',
    'Custom workouts crafted by {{coachFirstName}} and delivered in app{{#live}} + option for Live Video workouts{{/live}}',
    'Personalized nutrition plan for your unique dietary restrictions, preferences, & macronutrient needs',
    'Daily accountability, feedback, & support from your coach via text & video message',
  ],
  [WORKOUT_ONLY_PRODUCT_ID]: [
    'Custom workouts crafted by {{coachFirstName}} and delivered in app{{#live}} + option for Live Video workouts{{/live}}',
    'Daily accountability, feedback, & support from your coach via text & video message',
  ],
  [NUTRITION_ONLY_PRODUCT_ID]: [
    'Personalized nutrition plan for your unique dietary restrictions, preferences, & macronutrient needs',
    'Daily accountability, feedback, & support from your coach via text & video message',
  ],
  [KICKOFF_STANDARD_PRODUCT_ID]: [
    'Personalized workouts',
    'Personalized nutrition coaching',
    'Daily feedback & guidance from your certified coach',
  ],
  NUTRITION_LANDING_PAGE_SECTION_1: [
    'Integrated nutrition coaching',
    'Personalized plan for your unique dietary restrictions, preferences, & macronutrient needs',
    'Daily accountability, feedback, & support from your coach',
  ],
  NUTRITION_LANDING_PAGE_SECTION_2: [
    'Optional personal training with step-by-step guidance',
    'Optional 1-on-1 live video workouts',
  ],
  LANDING_PAGE_SECTION_1: [
    'Custom exercise program that meets you where you’re at',
    'Personalized workouts with step-by-step guidance',
    'Daily accountability, feedback, & support from your coach',
  ],
  LANDING_PAGE_SECTION_2: [
    'Optional personalized nutrition plan for your unique dietary restrictions, preferences, & macronutrient needs',
    'Optional 1-on-1 live video workouts',
  ],
  [`${KICKOFF_STANDARD_PRODUCT_ID}_SECTION_2`]: [
    'Add live workouts - from $13 / session',
  ],
}

function renderTemplate(template, data) {
  // Handle conditional blocks {{#key}} ... {{/key}}
  template = template.replace(
    /{{#(\w+)}}(.*?){{\/\1}}/gs,
    (_, key, content) => {
      return data[key] ? content : ''
    }
  )

  // Handle variable replacement {{ key }}
  template = template.replace(/{{\s*(\w+)\s*}}/g, (_, key) => {
    return data[key] !== undefined ? data[key] : ''
  })

  return template
}

interface IBulletPointsProps {
  productId: string
  coachFirstName?: string
  showLiveDescription?: boolean
  className?: string
  checkStyle?: string
  checkHeight?: number
  checkWidth?: number
  textStyle?: string
}

export default function BulletPoints({
  productId,
  coachFirstName,
  showLiveDescription,
  className,
  checkStyle,
  checkHeight,
  checkWidth,
  textStyle,
}: IBulletPointsProps) {
  const bullets = useMemo(() => {
    const bulletPoints = BULLET_POINTS_BY_PRODUCT_ID[productId]
    return bulletPoints?.map((bulletPoint, index) => (
      <DetailItem
        key={`bullet-${index}`}
        text={renderTemplate(bulletPoint, {
          coachFirstName,
          live: showLiveDescription,
        })}
        className={className}
        checkStyle={checkStyle}
        checkHeight={checkHeight}
        checkWidth={checkWidth}
        textStyle={textStyle}
      />
    ))
  }, [
    productId,
    coachFirstName,
    showLiveDescription,
    className,
    checkStyle,
    checkHeight,
    checkWidth,
    textStyle,
  ])

  return bullets
}
