import { useState } from 'react'

import { ClientSignUpAnalytics } from 'components/client-sign-up/analytics'
import s from './styles.module.css'
import { Button } from 'components/design-system/button'

const ReengagementForm = ({ onRetry, onCancelReengagement, signUpVariant }) => {
  const [hasRetried, setHasRetried] = useState(false)

  const handleRetry = () => {
    if (hasRetried) return

    ClientSignUpAnalytics.onResendReengagementText()
    onRetry()
    setHasRetried(true)
  }

  const handleCancelReengagement = () => {
    ClientSignUpAnalytics.onCancelReengagement()
    onCancelReengagement()
  }
  return (
    <div>
      <h4 className={s.reengagementHeader}>
        Thanks! We just texted you a link to finish signing up securely. Check
        your text messages.
      </h4>
      {!hasRetried && (
        <Button className={s.retryButtonB} size="small" onClick={handleRetry}>
          Resend text
        </Button>
      )}
      {hasRetried && <span className={s.retryConfirmation}>Sent!</span>}
      {onCancelReengagement && (
        <Button
          variant="text"
          size="small"
          className={s.cancelButtonB}
          onClick={handleCancelReengagement}>
          I entered my phone number incorrectly
        </Button>
      )}
    </div>
  )
}

export default ReengagementForm
